// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-content-template-content-template-js": () => import("/opt/build/repo/src/components/content-template/content-template.js" /* webpackChunkName: "component---src-components-content-template-content-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-what-we-do-js": () => import("/opt/build/repo/src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

